//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Default";

//Element
class Default extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            MJ449W : []
        }
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Tritan | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/tritan"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Tritan | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/tritan"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Tritan | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    ZN2506 = () => {
        //Element
        const TI382H = async() => {
            //Element
            const S5E5T0 = await Axios.get( "https://api.renga.com.tr/tritan", {} )
            //Element
            .then( RD44Y8 => {
                //Element
                this.setState({
                    //Element
                    MJ449W : RD44Y8.data
                })
            })
        }
        //Element
        TI382H()
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Tritan | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/tritan" )
        //Element
        this.ZN2506()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        let G377LG = "https://api.renga.com.tr/images/"
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Breadcrumb, { ID: "0", Title: "Tritan", Target: "/tritan" } )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 d62j49" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 v89t1n" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 position-relative t4n1r0" },
                                        //Element
                                        "Tritan"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 y0c044" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 h728di" },
                                //Element
                                React.createElement( "picture", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle hfn308" },
                                    //Element
                                    this.state.MJ449W.map( (T9622W) => {
                                        //Element
                                        return React.createElement( "img", { alt: T9622W.Title, title: T9622W.Title, src: G377LG + T9622W.Image, width: "120", height: "120" } )
                                    })
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:9 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-5 h728di" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 s98067" },
                                    //Element
                                    this.state.MJ449W.map( (T9622W) => {
                                        //Element
                                        return Parser( T9622W.Summary )
                                    })
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;