//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Axios from "axios";
//Install
import SEO from "./Function/SEO";
//Install
import Nav from "./Content/Navigation";
//Install
import Blog from "./Content/Blog";
//Install
import Round from "./Content/Round";
//Install
import Group from "./Content/Group";
//Install
import Award from "./Content/Award";
//Install
import Video from "./Content/Video";
//Install
import Tricker from "./Content/Tricker";
//Install
import Headline from "./Content/Headline";
//Install
import Reference from "./Content/Reference";

//Element
class Main extends Component {
    //Element
    constructor(props){
        //Element
        super(props)
        //Element
        this.state = {
            //Element
            IJ947I : [],
            //Element
            C4223G : [],
            //Element
            P40R72 : [],
            //Element
            R009XY : [],
            //Element
            BG309E : [],
            //Element
            T955MX : [],
            //Element
            H150YD : [],
            //Element
            C0L124 : []
        }
    }
    //Element
    PS1N29 = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/news", {} )
        //Element
        .then( E6328N => {
            //Element
            this.setState({
                //Element
                IJ947I : E6328N.data
            })
        })
    }
    //Element
    K6100W = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/reference", {} )
        //Element
        .then( GU620B => {
            //Element
            this.setState({
                //Element
                C4223G : GU620B.data
            })
        })
    }
    //Element
    L31U4B = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/blog", {} )
        //Element
        .then( B7WS61 => {
            //Element
            this.setState({
                //Element
                P40R72 : B7WS61.data
            })
        })
    }
    //Element
    X34CO9 = () => {
        //Element
        Axios.get( "https://api.renga.com.tr/prize", {} )
        //Element
        .then( C7AT73 => {
            //Element
            this.setState({
                //Element
                R009XY : C7AT73.data
            })
        })
    }
    //Element
    B4A287 = () => {
        //Element
        const F575RV = async() => {
            //Element
            const M4508T = await Axios.get( "https://api.renga.com.tr/slider", {} )
            //Element
            .then( D4U2C0 => {
                //Element
                this.setState({
                    //Element
                    BG309E : D4U2C0.data
                })
            })
        }
        //Element
        F575RV()
    }
    //Element
    FU367E = () => {
        //Element
        const HS7529 = async() => {
            //Element
            const ZS0683 = await Axios.get( "https://api.renga.com.tr/video", {} )
            //Element
            .then( R881C4 => {
                //Element
                this.setState({
                    //Element
                    T955MX : R881C4.data
                })
            })
        }
        //Element
        HS7529()
    }
    //Element
    K6100W = () => {
        //Element
        const G1787X = async() => {
            //Element
            const K9548V = await Axios.get( "https://api.renga.com.tr/reference", {} )
            //Element
            .then( T7614J => {
                //Element
                this.setState({
                    //Element
                    H150YD : T7614J.data
                })
            })
        }
        //Element
        G1787X()
    }
    //Element
    T5G679 = () => {
        //Element
        const A330FC = async() => {
            //Element
            const D2IU77 = await Axios.get( "https://api.renga.com.tr/document", {} )
            //Element
            .then( DF5953 => {
                //Element
                this.setState({
                    //Element
                    C0L124 : DF5953.data
                })
            })
        }
        //Element
        A330FC()
    }
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Anasayfa | Renga ® - Renga.com.tr"
        //Element
        T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        HU221M.content = "https://renga.com.tr/favicon.svg"
        //Element
        V337GE.content = "https://renga.com.tr/"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Anasayfa | Renga ® - Renga.com.tr"
        //Element
        D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        N94MJ9.content = "https://renga.com.tr/favicon.svg"
        //Element
        S577FO.content = "https://renga.com.tr/"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Anasayfa | Renga ® - Renga.com.tr"
        //Element
        W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        K08577.content = "https://renga.com.tr/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Anasayfa | Renga ® - Renga.com.tr"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://renga.com.tr/" )
        //Element
        this.PS1N29()
        //Element
        this.K6100W()
        //Element
        this.L31U4B()
        //Element
        this.X34CO9()
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
        //Element
        this.B4A287()
        //Element
        this.FU367E()
        //Element
        this.K6100W()
        //Element
        this.T5G679()
    }
    //Element
    render() {
        //Element
        let HN5I39 = "/blog-detay/"
        //Element
        let G300T6 = "/odul/"
        //Element
        let B366VA = "/"
        //Element
        const QM7R65 = {
            //Element
            spaceBetween    : "0",
            //Element
            slidesPerView   : "1",
            //Element
            autoplay        : {
                //Element
                delay       : 5000
            },
            //Element
            loop            : true,
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 1
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const M527Y0 = {
            //Element
            spaceBetween    : "16",
            //Element
            slidesPerView   : "4",
            //Element
            freeMode        : true,
            //Element
            navigation      : {
                //Element
                nextEl      : "#Web_D5026K",
                //Element
                prevEl      : "#Web_LZ2Y65"
            },
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 4
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const D33259 = {
            //Element
            spaceBetween    : "16",
            //Element
            slidesPerView   : "4",
            //Element
            navigation      : {
                //Element
                nextEl      : "#Blog_Next",
                //Element
                prevEl      : "#Blog_Prev"
            },
            //Element
            freeMode        : true,
            //Element
            breakpoints     : {
                //Element
                320:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                360:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                480:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                504:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                600:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                768:{
                    //Element
                    slidesPerView : 1
                },
                //Element
                1024:{
                    //Element
                    slidesPerView : 3
                },
                //Element
                1280:{
                    //Element
                    slidesPerView : 4
                }
            },
            //Element
            modules         : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 z3k56e" },
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 cqd851" },
                //Element
                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...QM7R65 },
                    //Element
                    this.state.BG309E.map( (J7K70O) => {
                        //Element
                        return React.createElement( SwiperSlide, { key: J7K70O.ID },
                            //Element
                            React.createElement( Headline, { ID: J7K70O.ID.toString(), Title: J7K70O.Title, Image: J7K70O.Image, Target: J7K70O.Target } )
                        )
                    })
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 a5t428" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p9838t" },
                                //Element
                                React.createElement( Round, { ID: "1", Title: "Hakkımızda", Target: "/kurumsal" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p9838t" },
                                //Element
                                React.createElement( Round, { ID: "2", Title: "Sürdürebilirlik", Target: "/surdurulebilirlik" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 p9838t" },
                                //Element
                                React.createElement( Round, { ID: "3", Title: "Misyon ve Vizyon", Target: "/misyon-ve-vizyon" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 h6045r" },
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 j330kh" },
                    //Element
                    React.createElement( Group, { ID: "0", Title: "Mataralar", Image: "b022cffbefa9ebf464fdf9bae563d9ce", Target: "/urunler/?variety=44" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 j330kh" },
                    //Element
                    React.createElement( Group, { ID: "0", Title: "Mutfak Eşyaları", Image: "1b1614ae9fa3901b9e80607e353fdd4a", Target: "/urunler/?variety=50" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 j330kh" },
                    //Element
                    React.createElement( Group, { ID: "0", Title: "Bebek Ürünleri", Image: "743d5c4edc78879554c30166d6c282b1", Target: "/urunler/?variety=48" } )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-50 p-0 m-0 j330kh" },
                    //Element
                    React.createElement( Group, { ID: "0", Title: "Çocuk Ürünleri", Image: "e1b21d4e9ffad4549e6720b0572680e1", Target: "/urunler/?variety=47" } )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 h5373t" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 p959ju" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 w56q8v" },
                                    //Element
                                    React.createElement( "h4", { className: "float-start w-100 p-0 m-0 text-uppercase lh-1 r1166g" },
                                        //Element
                                        "Ödüller"
                                    )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative x85j4z" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            this.state.R009XY.map( (YU385D) => {
                                //Element
                                return React.createElement( Col, { key: YU385D.ID, md:3 },
                                    //Element
                                    React.createElement( Award, { ID: YU385D.ID.toString(), Title: YU385D.Title, One: YU385D.One, Two: YU385D.Two, Three: YU385D.Three, Image: YU385D.Image, Target: G300T6 + SEO(YU385D.Title) + B366VA + YU385D.ID } )
                                )
                            })
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 z7d34a" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 c565w8" },
                    //Element
                    React.createElement( "div", { className: "float-start p-0 m-0 a5i92c" },
                        //Element
                        this.state.IJ947I.map( (L32Y92) => {
                            //Element
                            return React.createElement( Tricker, { key: L32Y92.ID, ID: L32Y92.ID.toString(), Title: L32Y92.Title, Target: "/" } )
                        })
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 q25h43" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 u05ct2" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center o5774u" },
                                    //Element
                                    "Blog"
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 position-relative m26s4l" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    this.state.P40R72.map( (QG760T) => {
                                        //Element
                                        return React.createElement( Col, { key: QG760T.ID, md:6 },
                                            //Element
                                            React.createElement( Blog, { ID: QG760T.ID.toString(), Title: QG760T.Title, Summary: QG760T.Summary, Image: QG760T.Image, Time: "", Category: "", Target: HN5I39 + SEO(QG760T.Title) + B366VA + QG760T.ID } )
                                        )
                                    })
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 pt-5 pb-5 m-0 position-relative q541fx" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 ww6850" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Nav, {} )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 y469po" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...M527Y0 },
                                    //Element
                                    this.state.T955MX.map( (D7927Z) => {
                                        //Element
                                        return React.createElement( SwiperSlide, { key: D7927Z.ID },
                                            //Element
                                            React.createElement( Video, { ID: D7927Z.ID.toString(), Title: D7927Z.Title, Duration: D7927Z.Summary, Image: D7927Z.Image, Target: "/" } )
                                        )
                                    })
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 zw333n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 n40a3u" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center lh-1 e1470t" },
                                    //Element
                                    "Referanslar"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 h689ie" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    this.state.H150YD.map( (HC75T0) => {
                                        //Element
                                        return React.createElement( Col, { key: HC75T0.ID, md:"2 d-flex justify-content-center align-items-center" },
                                            //Element
                                            React.createElement( Reference, { ID: HC75T0.ID.toString(), Title: HC75T0.Title, Image: HC75T0.Image, Target: "/" } )
                                        )
                                    })
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "section", { className: "float-start w-100 p-0 m-0 zw333n" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 n40a3u" },
                                //Element
                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 text-center lh-1 e1470t" },
                                    //Element
                                    "Belgeler"
                                )
                            ),
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 h689ie" },
                                //Element
                                React.createElement( Row, {},
                                    //Element
                                    this.state.C0L124.map( (AO442P) => {
                                        //Element
                                        return React.createElement( Col, { key: AO442P.ID, md:"2 d-flex justify-content-center align-items-center" },
                                            //Element
                                            React.createElement( Reference, { ID: AO442P.ID.toString(), Title: AO442P.Title, Image: AO442P.Image, Target: "/" } )
                                        )
                                    })
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Main;