//Install
import React, { useEffect, useState } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import { useParams } from "react-router-dom";
//Install
import Axios from "axios";
//Install
import Parser from "html-react-parser";
//Install
import SEO from "../../Function/SEO";
//Install
import Nav from "../../Shortcode/Product/Navigation";
//Install
import Thumb from "../../Shortcode/Product/Thumb";
//Install
import Image from "../../Shortcode/Product/Image";
//Install
import Product from "../../Shortcode/Product/Product";
//Install
import Breadcrumb from "../../Shortcode/Breadcrumb/Product";

//Element
function Y26F5M(){
    //Element
    const { ID } = useParams()
    //Element
    let Z1K17M = " | Renga ® - Renga.com.tr"
    //Element
    useEffect( () => {
        //Element
        const G566D6 = async() => {
            //Element
            const V32Z4C = await Axios.get( "https://api.renga.com.tr/product/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( C16J5B => {
                //Element
                let OM175Z     = document.querySelector( "meta[property='og:title']" )
                //Element
                let T00A3U     = document.querySelector( "meta[property='og:description']" )
                //Element
                let HU221M     = document.querySelector( "meta[property='og:image']" )
                //Element
                let V337GE     = document.querySelector( "meta[property='og:url']" )
                //Element
                OM175Z.content = C16J5B.data[0].Title + Z1K17M
                //Element
                T00A3U.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                HU221M.content = "https://renga.com.tr/favicon.svg"
                //Element
                V337GE.content = window.location.href
                //Element
                let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
                //Element
                let D68001     = document.querySelector( "meta[name='twitter:description']" )
                //Element
                let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
                //Element
                let S577FO     = document.querySelector( "meta[name='twitter:url']" )
                //Element
                N09HE5.content = C16J5B.data[0].Title + Z1K17M
                //Element
                D68001.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                N94MJ9.content = "https://renga.com.tr/favicon.svg"
                //Element
                S577FO.content = window.location.href
                //Element
                let AHF319     = document.querySelector( "meta[itemprop='name'" )
                //Element
                let W2LB44     = document.querySelector( "meta[itemprop='description']" )
                //Element
                let K08577     = document.querySelector( "meta[itemprop='image']" )
                //Element
                AHF319.content = C16J5B.data[0].Title + Z1K17M
                //Element
                W2LB44.content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                K08577.content = "https://renga.com.tr/favicon.svg"
                //Element
                document.title = C16J5B.data[0].Title + Z1K17M
                //Element
                document.getElementsByTagName( "meta" )["description"].content = "Yaşamı kolaylaştıran, tarz sahibi ve sağlığa dost Renga ürünleri sektörün en prestijli tasarım ödüllerinin de sahibi."
                //Element
                document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", window.location.href )
            })
        }
        //Element
        G566D6()
    })
}
//Element
function V4349M(e){
    //Element
    let X617HL = e.target.attributes[1].nodeValue
    //Element
    let N1Z726 = document.querySelector( ".io4945 img" )
    //Element
    N1Z726.src = X617HL
}
//Element
function Default(){
    //Element
    Y26F5M()
    //Element
    const { ID } = useParams()
    //Element
    let PH24S3, L6866G, H2K0D1, N199QN, Z005DS, A68722
    //Element
    let I451E6, QE9673, E155JH
    //Element
    let H4P304, A00Y32, D119YR, K8C5I4
    //Element
    PH24S3 = "https://api.renga.com.tr/images/"
    //Element
    L6866G = "/urun/"
    //Element
    H2K0D1 = "/"
    //Element
    N199QN = "-"
    //Element
    const [ TC4461, Y908H4 ] = useState([])
    //Element
    const [ I78D6Y, X806IK ] = useState([])
    //Element
    const [ IYR911, YOX098 ] = useState([])
    //Element
    const [ GI007S, PGD307 ] = useState([])
    //Element
    const [ P24D64, Z4A89A ] = useState([])
    //Element
    const [ P2098W, Q78T8W ] = useState([])
    //Element
    useEffect( () => {
        //Element
        const K8744H = async() => {
            //Element
            const V52T7Y = await Axios.get( "https://api.renga.com.tr/product/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( O466O5 => {
                //Element
                Y908H4( O466O5.data )
            })
            //Element
            const S501GL = await Axios.get( "https://api.renga.com.tr/product/image/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( (U8H0X8) => {
                //Element
                X806IK( U8H0X8.data )
            })
            //Element
            const P42Z6S = await Axios.get( "https://api.renga.com.tr/product/color/select", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( YN6L76 => {
                //Element
                I451E6 = YN6L76.data[0].First
                //Element
                QE9673 = YN6L76.data[0].Second
                //Element
                E155JH = YN6L76.data[0].Third
            })
            //Element
            const H8N67N = await Axios.get( "https://api.renga.com.tr/color/select", {
                //Element
                params : {
                    //Element
                    ID : I451E6
                }
            })
            //Element
            .then( (GM988L) => {
                //Element
                YOX098( GM988L.data )
            })
            //Element
            const O49S62 = await Axios.get( "https://api.renga.com.tr/color/select", {
                //Element
                params : {
                    //Element
                    ID : QE9673
                }
            })
            //Element
            .then( (M7PJ64) => {
                //Element
                PGD307( M7PJ64.data )
            })
            //Element
            const GS2799 = await Axios.get( "https://api.renga.com.tr/color/select", {
                //Element
                params : {
                    //Element
                    ID : E155JH
                }
            })
            //Element
            .then( (C719C2) => {
                //Element
                Z4A89A( C719C2.data )
            })
            //Element
            const U93G20 = await Axios.get( "https://api.renga.com.tr/product/not", {
                //Element
                params : {
                    //Element
                    ID : ID
                }
            })
            //Element
            .then( (F3LV04) => {
                //Element
                Q78T8W( F3LV04.data )
            })
        }
        //Element
        K8744H()
    }, [])
    //Element
    TC4461.map( (QK8V92) => {
        //Element
        H4P304 = QK8V92.Title
    })
    //Element
    I78D6Y.map( (OJH266) => {
        //Element
        if( OJH266.First != null ){
            //Element
            A00Y32 = OJH266.First
        }
        //Element
        if( OJH266.Second != null ){
            //Element
            D119YR = OJH266.Second
        }
        //Element
        if( OJH266.Third != null ){
            //Element
            K8C5I4 = OJH266.Third
        }
    })
    //Element
    const W9Z412 = {
        //Element
        direction                        : "vertical",
        //Element
        effect                           : "slide",
        //Element
        slidesPerView                    : "3",
        //Element
        freeMode                         : true,
        //Element
        autoplay                         : {
            //Element
            delay                        : 3000,
            //Element
            reverseDirection             : true,
            //Element
            disableOnInteraction         : false,
        },
        //Element
        breakpoints                      : {
            //Element
            320: {
                //Element
                slidesPerView            : 2,
                //Element
                direction                : "horizontal",
                //Element
                autoplay                 : {
                    //Element
                    delay                : 3000,
                    //Element
                    reverseDirection     : false,
                    //Element
                    disableOnInteraction : false,
                }
            },
            //Element
            360: {
                //Element
                slidesPerView            : 1,
                //Element
                direction                : "horizontal",
                //Element
                autoplay                 : {
                    //Element
                    delay                : 3000,
                    //Element
                    reverseDirection     : false,
                    //Element
                    disableOnInteraction : false,
                }
            },
            //Element
            480: {
                //Element
                slidesPerView            : 1,
                //Element
                direction                : "horizontal",
                //Element
                autoplay                 : {
                    //Element
                    delay                : 3000,
                    //Element
                    reverseDirection     : false,
                    //Element
                    disableOnInteraction : false,
                }
            },
            //Element
            504: {
                //Element
                slidesPerView            : 1,
                //Element
                direction                : "horizontal",
                //Element
                autoplay                 : {
                    //Element
                    delay                : 3000,
                    //Element
                    reverseDirection     : false,
                    //Element
                    disableOnInteraction : false,
                }
            },
            //Element
            600:{
                //Element
                slidesPerView            : 1,
                //Element
                direction                : "horizontal",
                //Element
                autoplay                 : {
                    //Element
                    delay                : 3000,
                    //Element
                    reverseDirection     : false,
                    //Element
                    disableOnInteraction : false,
                }
            },
            //Element
            768: {
                //Element
                slidesPerView            : 1,
                //Element
                direction                : "horizontal"
            },
            //Element
            1024: {
                //Element
                slidesPerView            : 3
            },
            //Element
            1280: {
                //Element
                slidesPerView            : 3
            }
        },
        //Element
        modules                          : [ Navigation, Pagination, Autoplay ]
    }
    //Element
    const J650LK = {
        //Element
        spaceBetween    : "12",
        //Element
        slidesPerView   : "4",
        //Element
        navigation      : {
            //Element
            nextEl      : ".s525qg",
            //Element
            prevEl      : ".j2p09b"
        },
        //Element
        autoplay        : {
            //Element
            delay       : 3000
        },
        //Element
        loop            : true,
        //Element
        freeMode        : true,
        //Element
        breakpoints     : {
            //Element
            320:{
                //Element
                slidesPerView : 1
            },
            //Element
            360:{
                //Element
                slidesPerView : 1
            },
            //Element
            480:{
                //Element
                slidesPerView : 1
            },
            //Element
            504:{
                //Element
                slidesPerView : 1
            },
            //Element
            600:{
                //Element
                slidesPerView : 1
            },
            //Element
            768:{
                //Element
                slidesPerView : 1
            },
            //Element
            991:{
                //Element
                slidesPerView : 3
            },
            //Element
            1024:{
                //Element
                slidesPerView : 4
            },
            //Element
            1280:{
                //Element
                slidesPerView : 4
            }
        },
        //Element
        modules               : [ Navigation, Pagination, Autoplay ]
    }
    //Element
    const Content = React.createElement( "main", { className: "float-start w-100 p-0 m-0 k0885p" },
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 position-relative n4a725" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 ib891j" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            TC4461.map( (TO46K3) => {
                                //Element
                                Z005DS = L6866G + SEO( TO46K3.Title + N199QN + TO46K3.SubTitle ) + H2K0D1 + TO46K3.ID
                                //Element
                                return React.createElement( Breadcrumb, { key: ID, ID: String(ID), Title: TO46K3.Title, Target: Z005DS } )
                            })
                        )
                    )
                )
            )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 mt-5 p103b5" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 y7c4c5" },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...W9Z412 },
                                //Element
                                I78D6Y.map( (S9932B) => {
                                    //Element
                                    if( S9932B.First != null ){
                                        //Element
                                        return React.createElement( SwiperSlide, { key: S9932B.ID },
                                            //Element
                                            React.createElement( Thumb, { key: S9932B.ID, ID: S9932B.ID.toString(), Title: H4P304, Image: S9932B.First, Target: "/" } )
                                        )
                                    }
                                }),
                                //Element
                                I78D6Y.map( (R4QV78) => {
                                    //Element
                                    if( R4QV78.Second != null ){
                                        //Element
                                        return React.createElement( SwiperSlide, { key: R4QV78.ID },
                                            //Element
                                            React.createElement( Thumb, { key: R4QV78.ID, ID: R4QV78.ID.toString(), Title: H4P304, Image: R4QV78.Second, Target: "/" } )
                                        )
                                    }
                                }),
                                //Element
                                I78D6Y.map( (OJH266) => {
                                    //Element
                                    if( OJH266.Third != null ){
                                        //Element
                                        return React.createElement( SwiperSlide, { key: OJH266.ID },
                                            //Element
                                            React.createElement( Thumb, { key: OJH266.ID, ID: OJH266.ID.toString(), Title: H4P304, Image: OJH266.Third, Target: "/" } )
                                        )
                                    }
                                })
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:5 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-5 m-0 sn91s7" },
                            //Element
                            TC4461.map( (Q943SK) => {
                                //Element
                                return React.createElement( Image, { key: Q943SK.ID, ID: Q943SK.ID.toString(), Title: Q943SK.Title, Image: Q943SK.Image, Target: "/" } )
                            })
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:7 },
                        //Element
                        TC4461.map( (D3E66W) => {
                            //Element
                            return React.createElement( "div", { key: D3E66W.ID, className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 gb545c" },
                                //Element
                                React.createElement( "h1", { className: "float-start w-auto p-0 m-0 zx8136" },
                                    //Element
                                    D3E66W.Title
                                ),
                                //Element
                                React.createElement( "h2", { className: "float-start w-auto p-0 m-0 mt-2 text-center e526yh" },
                                    //Element
                                    D3E66W.SubTitle
                                ),
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 v0d189" },
                                    //Element
                                    React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-3 ms-3 hs382u" },
                                        //Element
                                        IYR911.map( (V55CT7) => {
                                            //Element
                                            return React.createElement( "li", { key: V55CT7.ID, className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 rounded-circle" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto p-0 m-0 rounded-circle", onClick: V4349M, "data-src" : PH24S3 + A00Y32, style: { backgroundColor: V55CT7.RGB } } )
                                            )
                                        }),
                                        //Element
                                        GI007S.map( (W32FE3) => {
                                            //Element
                                            return React.createElement( "li", { key: W32FE3.ID, className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-2 rounded-circle" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto p-0 m-0 rounded-circle", onClick: V4349M, "data-src" : PH24S3 + D119YR, style: { backgroundColor: W32FE3.RGB } } )
                                            )
                                        }),
                                        //Element
                                        P24D64.map( (G228CE) => {
                                            //Element
                                            return React.createElement( "li", { key: G228CE.ID, className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 ms-2 rounded-circle" },
                                                //Element
                                                React.createElement( "span", { className: "float-start w-auto p-0 m-0 rounded-circle", onClick: V4349M, "data-src" : PH24S3 + K8C5I4, style: { backgroundColor: G228CE.RGB } } )
                                            )
                                        })
                                    )
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 h-auto p-4 ps-0 pe-0 pt-1 m-0 mt-3 bg-white overflow-hidden w82ec9" },
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 position-relative mk6t09" },
                                        //Element
                                        TC4461.map( (D3E66W) => {
                                            //Element
                                            if( D3E66W.iFrame != null && D3E66W.iFrame != "null" ){
                                                //Element
                                                return Parser(D3E66W.iFrame)
                                            }
                                        })
                                    )
                                )
                            )
                        })
                    )
                )
            )
        ),
        //Element
        React.createElement( "section", { className: "float-start w-100 p-0 m-0 j563d9" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 t0h17s" },
                            //Element
                            React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...J650LK },
                                //Element
                                P2098W.map( (W3V61R) => {
                                    //Element
                                    return React.createElement( SwiperSlide, { key: W3V61R.ID },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 od8w32" },
                                            //Element
                                            React.createElement( Product, { key: W3V61R.ID, ID: W3V61R.ID.toString(), Title: W3V61R.Title, Summary: W3V61R.SubTitle, Image: W3V61R.Image, Target: L6866G + SEO( W3V61R.Title + N199QN + W3V61R.SubTitle ) + H2K0D1 + W3V61R.ID } )
                                        )
                                    )
                                })
                            ),
                            //Element
                            React.createElement( Nav, {} )
                        )
                    )
                )
            )
        )
    )
    //Element
    return(
        Content
    )
}
//Element
export default Default;